import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import Checkbox from 'Ui/Checkbox'
import { notify } from '../../../actions/notifications'
import { getUser } from '../../../api/user'
import { setCurrentUser } from 'Actions/user'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AmlModal/aml-modal.sass'

const ppeOriginValues = [
  'Épargne',
  'Héritage',
  'Donation',
  'Crédit',
  'Réinvestissement ou cession d’actifs mobiliers ou immobiliers',
  'Prestations ou Indemnités',
]

class PpeOriginModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ppeOrigin: [],
      ppeOtherChecked: false,
    }
  }

  handleSubmit(values, actions) {
    const { dispatch, currentUser, close } = this.props

    fetch(`/api/user/${currentUser.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(values),
    })
      .then(res => {
        actions.setSubmitting(false)
        if (res.status !== 200) throw new Error(res.error)
        return res
      })
      .then(res => res.json())
      .then(() => {
        dispatch(notify('success', 'Information enregistrée avec succès.'))
        close()
        getUser(res => {
          dispatch(setCurrentUser(res))
        })
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        if (e) dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }

  setPpeOrigin(value, setFieldTouched, validateForm) {
    setFieldTouched('ppeOriginValidator', true)

    if (this.state.ppeOrigin.includes(value)) {
      this.setState({ ppeOrigin: pull(this.state.ppeOrigin, value) }, validateForm)
    } else {
      this.setState({ ppeOrigin: [...this.state.ppeOrigin, value] }, validateForm)
    }
  }

  renderPpeOriginQuestion() {
    const { currentUser } = this.props
    const { firstname, name } = currentUser.representant

    return (
      <Formik
        initialValues={{
          ppeOriginOther: '',
          ppeOriginValidator: null,
        }}
        validate={values => {
          this.state.ppeOrigin.length ||
            (this.state.ppeOtherChecked && values.ppeOriginOther)
            ? {}
            : {
              ppeOriginValidator:
                'Il est nécessaire de cocher une de ces cases.',
            }
        }}
        onSubmit={(values, actions) => this.handleSubmit({
          ppeOrigin: this.state.ppeOrigin.join(', '),
          ppeOriginOther: values.ppeOriginOther,
        }, actions)}
        render={({ values, isSubmitting, errors, touched, setFieldValue, setFieldTouched, validateForm }) => {
          return (
            <Form>
              <div className="form-block">
                <div className="label-form">
                  <span className="step-subtitle">Attestation d'origine des fonds</span>
                </div>
                <div className="radio-list">
                  <div className="ppe-text mb-5">
                    Je soussigné {firstname} {name}, atteste que les fonds m’ayant permis de
                    constituer le capital social de ma société ont pour origine (plusieurs choix
                    possibles) :
                  </div>
                  {ppeOriginValues.map(val => (
                    <label key={val}>
                      <Checkbox
                        type="checkbox"
                        name="ppeOrigin"
                        value={val}
                        onChange={() =>
                          this.setPpeOrigin(val, setFieldTouched, validateForm)
                        }
                        checked={this.state.ppeOrigin.includes(val)}
                      />
                      <span>{val}</span>
                    </label>
                  ))}
                  <label>
                    <Checkbox
                      type="checkbox"
                      name="ppeOrigin"
                      onChange={() => {
                        setFieldTouched('ppeOriginValidator', true)
                        this.setState({ ppeOtherChecked: !this.state.ppeOtherChecked }, () => {
                          validateForm()
                        })
                      }}
                      checked={this.state.ppeOtherChecked}
                    />
                    <span>Autre :</span>
                  </label>
                  <Field name="ppeOriginOther" component="textarea" className="Input-group" />
                  <span className="input-error-message">
                    {(touched.ppeOriginValidator && errors.ppeOriginValidator) || ''}
                  </span>
                </div>
                {this.renderSubmitButton(isSubmitting)}
              </div>
            </Form>
          )
        }}
      />
    )
  }

  renderSubmitButton(isSubmitting) {
    return (
      <div className="right-container mb-8">
        <SubmitSpinnerButton isSubmitting={isSubmitting}>
          <button type="submit" className="btn valid small-height">
            Valider
          </button>
        </SubmitSpinnerButton>
      </div>
    )
  }

  render() {
    return (
      <div className="AmlModal">
        {this.renderPpeOriginQuestion()}
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(PpeOriginModal)
