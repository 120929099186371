import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import Tooltip from 'rc-tooltip'
import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import CountryField from 'Ui/formik-fields/CountryField'
import Radio from 'Ui/Radio'
import AddressGeocode from 'Ui/AddressGeocode'
import MainNav from '../../layout/MainNav'
import { notify } from 'Actions/notifications'
import handleValidate from 'Helpers/validators'
import fetchWrapper from 'Helpers/fetch-wrapper'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import 'Ui/tooltip.sass'
import NetheosWrapper from '../../SubscriptionPage/NetheosWrapper/NetheosWrapper'
import { camelCase, mapKeys, pick } from 'lodash'
import { getUser } from '../../../api/user'
import { setCurrentUser } from '../../../actions/user'
import { openModal } from '../../../actions/ui'

// Identité du président / gérant : 
// Prénom : [pré-rempli avec les infos Forest] + bouton “Modifier”
// Nom : [pré-rempli avec les infos Forest] + bouton “Modifier”
// Adresse personnelle : [pré-rempli avec les infos Forest] + bouton “Modifier”
// Téléphone : [pré-rempli avec les infos Forest] + bouton “Modifier”
// Justificatif d’identité : [demander à Ced wording actuel tunnel inscription]
// Justificatif de domicile : [demander à Ced wording actuel tunnel inscription]
// Identité de votre comptable : 
// Comptable ou pas
// Si oui, Nom cabinet comptable et adresse cabinet comptable
// Lieu d’activité : adresse postale (champs à créer dans Forest)
// Génère le doc “Attestation comptable” à signer via Yousign comme pour la popup “Attestation comptable” ci-dessus

const representantFields = [
  {
    key: 'firstname',
    subtitle: 'Prénom',
    disabled: true,
  },
  {
    key: 'name',
    subtitle: 'Nom',
    disabled: true,
  },
  {
    key: 'address',
    subtitle: 'Adresse',
  },
  {
    key: 'address2',
    subtitle: 'Optionnel : complément d’adresse :',
  },
  {
    key: 'cp',
    subtitle: 'Code postal',
  },
  {
    key: 'city',
    subtitle: 'Ville',
  },
]

const telField = {
  key: 'tel',
  type:'tel',
  subtitle: 'Numéro de téléphone',
}


const fieldValidators = {
  tel: ['tel', 'required'],
  address: ['no-special', 'no-leading-space', 'required'],
  address2: ['no-special', 'no-leading-space'],
  cp: ['no-punctuation-except-hyphen', 'no-special', 'no-leading-space', 'max-length-14', 'required'],
  city: ['no-punctuation-except-hyphen', 'no-special', 'no-leading-space', 'required'],
  country: ['notZero'],
}

const expertFieldsValidators = {
  expertName: ['required'],
  expertAddress: ['required'],
}


const renderTextField = (
  touched,
  errors,
  { key, label, subtitle, type = 'text', as = 'input', rows = 3, maxLength, disabled = false },
) => {
  return (
    <div className="representant-field">
      {subtitle && <p className="step-subtitle mb-3 text-left font-normal">{subtitle}</p>}
      <Field
        key={key}
        name={key}
        type={type}
        className="Input-group"
        placeholder={label}
        as={as}
        rows={rows}
        style={as === 'textarea' ? { height: 'auto' } : {}}
        maxLength={maxLength || (type === 'text' ? 200 : undefined)}
        disabled={disabled}
      />
      <span className="input-error-message">{(touched[key] && errors[key]) || ''}</span>
    </div>
  )
}

class ActualisationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      netheosToken: null,
      actualisation: null,
      actualisationLoaded: false,
      activityLocation: null,
    }
  }

  getActualisation(userId) {
    const { dispatch, currentUser } = this.props
    console.log('currentUser.id --------------------------------->')
    console.log(currentUser.id)
    const id = userId || currentUser.id

    fetchWrapper(`/api/user/${id}/actualisation-in-progress`)
      .then(res => {
        console.log('res --------------------------------->')
        console.log(res)
        if (res.actualisation) {
          this.setState({
            step: 2,
            netheosToken: res.token,
            actualisation: res.actualisation,
            actualisationLoaded: true,
          })
        } else {
          this.setState({ actualisationLoaded: true })
        }
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        if (e) dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }

  componentDidMount() {
    const { dispatch, currentUser } = this.props

    if (!currentUser.id) {
      getUser(res => {
        dispatch(setCurrentUser(res))
        this.setState({
          activityLocation: res.activity_location || '',
        })
        this.getActualisation(res.id)
      })
    } else {
      this.setState({
        activityLocation: currentUser.activity_location || '',
      })
      this.getActualisation()
    }
  }

  openConfirmModal(values, actions) {
    const { dispatch } = this.props

    dispatch(openModal('representantConfirmation', {
      variant: 'actualisation',
      name: `${values.firstname} ${values.name}`,
      address: `${values.address}, ${values.cp} ${values.city}`,
      tel: values.tel,
      onConfirm: () => this.handleSubmitForm(values, actions),
      afterClose: () => actions.setSubmitting(false),
    }))
  }

  handleSubmitForm(values, actions) {
    const { dispatch, currentUser } = this.props

    fetch(`/api/user/${currentUser.id}/actualisation`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(values),
    })
      .then(res => {
        actions.setSubmitting(false)
        if (res.status !== 200) throw new Error(res.error)
        return res
      })
      .then(res => res.json())
      .then(res => {
        dispatch(notify('success', 'Informations enregistrées avec succès.'))

        this.setState({
          step: 2,
          netheosToken: res.token
        })
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        if (e) dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }

  finalizeActualisation() {
    const { dispatch, currentUser } = this.props
    
    fetchWrapper(`/api/user/${currentUser.id}/actualisation/finalize`, {
      method: 'PUT',
    }).then(() => {
      window.location.replace('/mon-compte')
    }).catch((e) => {
      console.log('e ------------------------------------------>')
      console.log(e)
      if (e) dispatch(notify('error', 'Une erreur est survenue.'))
    })
  }

  handleValidation(values) {
    const expertError =
      values.hasExpert ? handleValidate(expertFieldsValidators)(values) : {}

    const activityLocationError =
      values.hasActivityLocation ? this.activityLocationValidation(values.activityLocation) : {}

    const otherErrors = handleValidate(fieldValidators)(values)

    return {
      ...activityLocationError,
      ...expertError,
      ...otherErrors,
    }
  }

  setHasActivityLocation(value, setFieldValue) {
    setFieldValue('hasActivityLocation', value)

    if (!value) {
      setFieldValue('activityLocation', '')
      this.setState({ activityLocation: '' })
    }
  }

  handleSelectActivityLocation(address, setFieldValue) {
    this.setState({ activityLocation: address.formattedAddress }, () => {
      setFieldValue('activityLocation', address.formattedAddress)
    })
  }

  activityLocationValidation(activityLocation) {
    if (!activityLocation) {
      return { activityLocation: 'Merci d’indiquer un lieu d’activité' }
    }

    if (!this.state.activityLocation) {
      return { activityLocation: 'Adresse imprécise.' }
    }

    if (activityLocation !== this.state.activityLocation) {
      return {
        activityLocation: 'Adresse non valide',
      }
    }

    return {}
  }

  renderTooltip() {
    const tooltipText = (
      <div className="Tooltip">
        Nous avons l’obligation légale de connaitre le nom de votre cabinet comptable. Si vous n’en
        avez pas, vous serez vous-même désigné comme comptable.
      </div>
    )

    return (
      <Tooltip placement="top" trigger={['click']} overlay={tooltipText}>
        <span className="Tooltip-trigger">?</span>
      </Tooltip>
    )
  }

  setHasExpert(value, setFieldValue) {
    setFieldValue('hasExpert', value)

    if (!value) {
      setFieldValue('expertName', '')
      setFieldValue('expertAddress', '')
    }
  }

  getInitialValues() {
    const { currentUser } = this.props

    return mapKeys({
      ...pick(currentUser, [
        'has_expert',
        'expert_name',
        'expert_address',
        'has_activity_location',
        'activity_location',
      ]),
      ...pick(currentUser.representant, [
        'firstname',
        'name',
        'address',
        'address2',
        'cp',
        'city',
        'tel',
        'country',
      ]),
    }, (v, k) => camelCase(k))
  }

  renderContent() {
    const { step, netheosToken, actualisationLoaded } = this.state

    if (!actualisationLoaded) return null

    if (step === 2 && netheosToken) {
      return (
        <div className="Step-contract-container mx-[auto] pb-16">
          <div className="text-2xl text-center mb-8">Mise à jour réglementaire de votre fiche client</div>
          <NetheosWrapper
            onValidated={() => {
              this.finalizeActualisation()
            }}
            token={netheosToken}
          />
        </div>
      )
    }

    return (
      <Formik
        initialValues={this.getInitialValues()}
        validate={this.handleValidation.bind(this)}
        onSubmit={(values, actions) => this.openConfirmModal(values, actions)}
        render={({
          values,
          setFieldValue,
          errors,
          touched,
          setFieldError,
          isSubmitting,
        }) => {
          return (
            <div className="Step connexion-container Step-contract-container pb-16">
              <div className="text-2xl text-center mb-8">Mise à jour réglementaire de votre fiche client</div>
              <div className="text-left text-normal-gray mb-8">Conformément à nos obligations légales en tant que société de domiciliation, nous sommes contraints de vous demander de confirmer régulièrement les informations suivantes :</div>
              <Form>
                <h2 className="step-title">Identité du président / gérant :</h2>
                <div className="form-block">
                  <div className="representant-form-container">
                    {representantFields.map(f => renderTextField(touched, errors, f))}
                    <div className="representant-field">
                      <div className="step-subtitle mb-3 text-left">Pays</div>
                      <CountryField errors={errors} touched={touched} fieldName="country" />
                    </div>
                    {renderTextField(touched, errors, telField)}
                  </div>
                </div>

                <h2 className="step-title">Identité de votre comptable :</h2>
                <div className="form-block">
                  <div className="label-form row">
                    <span className="step-subtitle lh22">Votre cabinet d'expert comptable</span>
                    {this.renderTooltip()}
                  </div>
                  <div className="radio-list">
                    <label>
                      <Radio
                        type="radio"
                        name="hasExpert"
                        value="false"
                        onChange={() => this.setHasExpert(false, setFieldValue)}
                        checked={!values.hasExpert}
                      />
                      <span>Je n'ai pas d'expert comptable</span>
                    </label>
                    <label>
                      <Radio
                        type="radio"
                        name="hasExpert"
                        value="true"
                        onChange={() => this.setHasExpert(true, setFieldValue)}
                        checked={!!values.hasExpert}
                      />
                      <span>J'ai un expert comptable :</span>
                    </label>
                  </div>
                  <div className="inputs-block">
                    <div className="input">
                      <Field
                        name="expertName"
                        type="text"
                        className="Input-group"
                        placeholder="Nom du cabinet comptable"
                        onChange={e => {
                          setFieldValue('expertName', e.target.value)
                          if (values.hasExpert === false) {
                            setFieldValue('hasExpert', true)
                          }
                        }}
                      />
                      <span className="input-error-message">
                        {(values.hasExpert && touched.expertName && errors.expertName) ||
                          ''}
                      </span>
                    </div>
                    <div className="input">
                      <Field
                        name="expertAddress"
                        type="text"
                        className="Input-group"
                        placeholder="Adresse du cabinet comptable"
                        onChange={e => {
                          setFieldValue('expertAddress', e.target.value)
                          if (values.hasExpert === false) {
                            setFieldValue('hasExpert', true)
                          }
                        }}
                      />
                      <span className="input-error-message">
                        {(values.hasExpert &&
                          touched.expertAddress &&
                          errors.expertAddress) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="spacer-20" />
                </div>

                <div className="form-block">
                  <div className="label-form">
                    <div className="step-subtitle text-left lh22">Lieu d’activité : exercez-vous votre activité à une adresse précise et fixe ? (Exemple : votre domicile personnel, vos bureaux, votre boutique…)</div>
                  </div>
                  <div className="radio-list">
                    <label>
                      <Radio
                        type="radio"
                        name="hasActivityLocation"
                        value="false"
                        onChange={() => this.setHasActivityLocation(false, setFieldValue)}
                        checked={!values.hasActivityLocation}
                      />
                      <span>Non</span>
                    </label>
                    <label>
                      <Radio
                        type="radio"
                        name="hasActivityLocation"
                        value="true"
                        onChange={() => this.setHasActivityLocation(true, setFieldValue)}
                        checked={values.hasActivityLocation}
                      />
                      <span>Oui :</span>
                    </label>
                  </div>
                  <div className="inputs-block">
                    <div className="input">
                      <Field
                        name="activityLocation"
                        component={AddressGeocode}
                        className="Input-group input"
                        placeholder="Adresse du lieu d'activité"
                        onChange={value => {
                          setFieldValue('activityLocation', value)
                          if (!values.hasActivityLocation) {
                            setFieldValue('hasActivityLocation', true)
                          }
                        }}
                        onSelect={address => this.handleSelectActivityLocation(address, setFieldValue)}
                        onError={error => setFieldError('expeditionAddress', error)}
                      />
                      <span className="input-error-message">
                        {(values.hasActivityLocation && touched.activityLocation && errors.activityLocation) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="spacer-20" />
                  <SubmitSpinnerButton isSubmitting={isSubmitting}>
                    <button type="submit" className="btn valid connexion-btn">
                      Valider
                    </button>
                  </SubmitSpinnerButton>
                </div>
              </Form>
            </div>
          )
        }}
      />
    )
  }

  render() {
    return (
      <div className='main-content'>
        <MainNav lightVersion />
        <div className="bg-light-grey pt-8">
          {this.renderContent()}
        </div>
      </div>
    )
  }
}


const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(ActualisationPage)
