import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomerNavigationLink from './CustomerNavigationLink'

import '../../layout/mainNav.sass'
import './customer-navigation.sass'

class CustomerNavigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      opened: false,
      lackPayment: false,
    }
  }

  toggleMenu() {
    this.setState({ opened: !this.state.opened })
  }

  isPaymentStatusUnpaid() {
    const { currentUser } = this.props
    if (!currentUser) return false
    return ['En retard', 'Impayé', 'Annulé', 'Attente SCA'].includes(currentUser.payment_status)
  }

  renderLogo() {
    return (
      <Link to={`/`} className="logo-link">
        <div className="logo">
          <span className="logo-icon" />
          <p>ma-domiciliation.fr</p>
        </div>
      </Link>
    )
  }

  renderLogout() {
    return (
      <form action="/api/logout" method="post">
        <button type="submit" className="logout-btn">
          <img src="/assets/icons/ic-d-connexion.svg" alt="Déconnexion" />
          Déconnexion
        </button>
      </form>
    )
  }

  renderMenuMobile() {
    return (
      <div id="menu-mobile" className={this.state.opened ? '' : 'hidden'}>
        <div className="container-logo-close">
          <Link to={`/`}>
            <div className="logo-light">
              <span className="logo-icon-light" />
              <p>ma-domiciliation.fr</p>
            </div>
          </Link>
          <div className="close-menu" onClick={() => this.toggleMenu()} />
        </div>
        <div className="menu-link">
          {this.renderNavigationLinks()}
          {this.renderLogout()}
        </div>
      </div>
    )
  }

  render() {
    const { children, currentUser } = this.props
    const disabledClass = this.isPaymentStatusUnpaid()
      ? ' main-nav-disabled'
      : ''

    return (
      <React.Fragment>
        <div className={`customer-area-main-nav main-nav${disabledClass}`}>
          <div className="nav-logo">
            <div className="customer-logo-container">
              <div className="drop-button" onClick={() => this.toggleMenu()} />
              {this.renderLogo()}
            </div>
            <div className="desktop">
              <div className="customer-area-container">
                <div className="drop-button" onClick={() => this.toggleMenu()} />
                <div
                  className={`customer-nav-block ${this.state.opened ? '' : 'customer-nav-hidden'}`}
                >
                  <Link to="/">
                    <div className="customer-logo-block">
                      <img src="/assets/icons/ic-logo.svg" alt="ma-domiciliation.fr" />
                      <div>ma-domiciliation.fr</div>
                    </div>
                  </Link>
                  <div className="menu-links-desktop">{this.renderNavigationLinks()}</div>
                </div>
                <div className="customer-area-right">
                  <div className="customer-navigation-top">{this.renderLogout()}</div>
                  <div className="customer-area-content">{children}</div>
                </div>
              </div>
            </div>
          </div>
          {this.renderMenuMobile()}
        </div>
        <div className="customer-area-content-mobile">{children}</div>
      </React.Fragment>
    )
  }

  renderNavigationLinks() {
    const { currentPage, currentUser } = this.props
    const disabled = this.isPaymentStatusUnpaid()

    return (
      <React.Fragment>
        {currentUser.is_domiciliated_user && (
          <>
            <CustomerNavigationLink
              focus={currentPage}
              icon="ic-bo-te-aux-lettres"
              label="Boîte aux lettres"
              route="lettres"
              disabled={disabled}
            />
            <CustomerNavigationLink
              focus={currentPage}
              icon="ic-adresse"
              label="Adresse"
              route="adresse"
              disabled={disabled}
            />
            <CustomerNavigationLink
              focus={currentPage}
              icon="ic-abonnement"
              label="Abonnement"
              route="abonnement"
              disabled={disabled}
            />
            <CustomerNavigationLink
              focus={currentPage}
              icon="ic-contrat"
              label="Contrat"
              route="contrat"
              disabled={disabled}
            />
          </>)}
        <CustomerNavigationLink
          focus={currentPage}
          icon="ic-factures"
          label="Factures"
          route="factures"
          disabled={disabled}
        />
        {currentUser.is_domiciliated_user && (
          <CustomerNavigationLink
            focus={currentPage}
            icon="moyen-de-paiement"
            label="Moyen de paiement"
            route="paiement"
            disabled={disabled}
          />)}
        <CustomerNavigationLink
          focus={currentPage}
          icon="ic-contrat"
          label="Formalités"
          route="formalites"
          disabled={disabled}
        />
        <Link to={'/contact'} target="_blank">
          <div className="customer-navigation-link">
            <div className="customer-navigation-icon">
              <img src="/assets/icons/ic-aide.svg" alt="Aide" />
            </div>
            <div>Aide</div>
          </div>
        </Link>
      </React.Fragment>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(CustomerNavigation)
