import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Tooltip from 'rc-tooltip'
import { head, get } from 'lodash'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'
import CustomerAreaTitle from '../Display/CustomerAreaTitle'
import CustomerAreaInfoLine from '../Display/CustomerAreaInfoLine'
import Button from 'Ui/Button'

import { formatPrice } from 'Helpers/format-helper'
import { STRIPE_PUBLIC_KEY } from 'Config/constants'
import { notify } from 'Actions/notifications'
import { openModal } from 'Actions/ui'
import { setCurrentUser } from 'Actions/user'
import { getUser } from '../../../api/user'

import '../Display/CustomerAreaDisplay.sass'
import 'rc-tooltip/assets/bootstrap.css'
import 'Ui/tooltip.sass'
import { axiosClientNoCamelize } from '../../../api/axios-client'

const UNSUBSCRIBE_TYPEFORM_URL = 'https://form.typeform.com/to/PYzSiFl3'

class SubscriptionPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      subscription: null,
      nextInvoice: null,
      customer: null,
      prorationsLoading: false,
      changeOfferLoading: false,
    }
  }

  componentDidMount() {
    this.fetchCustomer()
    this.fetchSubscriptions()
  }

  fetchCustomer() {
    fetch(`/api/user/customer-details`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        this.setState({ customer: res })
      })
      .catch(() =>
        this.props.dispatch(
          notify(
            'error',
            'Une erreur est survenue lors de la récupération des détails de votre abonnement.',
          ),
        ),
      )
  }

  fetchSubscriptions() {
    fetch(`/api/user/subscription-details`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        this.setState({ subscription: res.subscription, nextInvoice: res.nextInvoice })
      })
      .catch(() =>
        this.props.dispatch(
          notify(
            'error',
            'Une erreur est survenue lors de la récupération des détails de votre abonnement.',
          ),
        ),
      )
  }

  getSubData() {
    const { subscription } = this.state
    if (!subscription) return { interval: null, basePrice: null }

    const priceTotal = subscription.items.data.reduce((acc, item) => acc + item.plan.amount, 0)
    const { interval, interval_count } = subscription.items.data[0].plan

    return {
      interval,
      interval_count,
      basePrice: interval === 'month' ? priceTotal : priceTotal / 11,
    }
  }

  refreshUser() {
    const { dispatch } = this.props

    getUser(res => {
      dispatch(setCurrentUser(res))
    })
  }

  async handleChangeOffer(stripe, { type, interval }) {
    const { dispatch } = this.props
    this.setState({ changeOfferLoading: true })
    const url = type === 'interval'
      ? '/api/user/change-payment-interval'
      : '/api/user/change-expedition-to-scan'

    const body = type === 'interval' ? { interval } : {}

    try {
      const res = await axiosClientNoCamelize.post(url, body)

      console.log('res change offer ------------------------------------------>')
      console.log(res)
      const { validated, client_secret, subscription } = res.data
      this.setState({ subscription })

      if (!validated) {
        const res = await stripe.handleCardPayment(client_secret, {})
        console.log('res handleCardPayment ------------------------------------------>')
        console.log(res)

        this.setState({ changeOfferLoading: false })

        if (res.error) {
          dispatch(
            openModal('notification', {
              message: 'Changement d’offre validé, mais le paiement a été refusé, veuillez vérifier l’approvisionnement de votre carte et renseigner à nouveau un moyen de paiement dans l’onglet "Moyen de paiement".',
              buttonText: 'Ok',
            }),
          )

          return
        }
      }

      this.setState({ changeOfferLoading: false })
      dispatch(notify('success', 'Changement validé.'))
      this.fetchCustomer()
      this.fetchSubscriptions()
      this.refreshUser()
    } catch (e) {
      console.log('e ------------------------------------------>')
      console.log(e)
      this.setState({ changeOfferLoading: false })
      this.props.dispatch(
        notify('error', 'Une erreur est survenue lors de la modification de votre abonnement.'),
      )
    }
  }

  async handleClickChangeScan(stripe) {
    const { interval } = this.getSubData()
    if (interval === 'month') return this.handleChangeOffer(stripe, { type: 'scan' })
    
    this.setState({ prorationsLoading: true })

    try {
      const res = await axiosClientNoCamelize.get('/api/user/change-expedition-to-scan-prorate')
      this.setState({ prorationsLoading: false })

      console.log('res --------------------------------->')
      console.log(res)
      const proration = res.data.reduce((acc, item) => acc + item.amount, 0) / 100

      this.props.dispatch(
        openModal('confirmPaymentScan', {
          onValidate: () => this.handleChangeOffer(stripe, { type: 'scan' }),
          proration,
          balance: this.getTTCCustomerBalance() / -100,
        }),
      )
    } catch (e) {
      this.setState({ prorationsLoading: false })
      console.log('e ------------------------------------------>')
      console.log(e)
      this.props.dispatch(
        notify(
          'error',
          'Une erreur est survenue lors de la récupération des détails de votre abonnement.',
        ),
      )
    }
  }

  async getIntervalProrationsAndOpenModal(stripe) {
    const { basePrice, interval } = this.getSubData()
    const newInterval = interval === 'year' ? 'month' : 'year'
    this.setState({ prorationsLoading: true })

    try {
      const res = await axiosClientNoCamelize.get(`/api/user/change-payment-prorate?interval=${newInterval}`)
      this.setState({ prorationsLoading: false })
      const proration = res.data.reduce((acc, item) => acc + item.amount, 0) / -100

      this.props.dispatch(
        openModal('confirmPaymentInterval', {
          onValidate: () => this.handleChangeOffer(stripe, { type: 'interval', interval: newInterval }),
          currentInterval: interval,
          basePrice: (interval === 'month' ? basePrice : basePrice / 11) / 100,
          proration,
          balance: this.getTTCCustomerBalance() / -100,
        }),
      )
    } catch (e) {
      this.setState({ prorationsLoading: false })
      console.log('e ------------------------------------------>')
      console.log(e)
      this.props.dispatch(
        notify(
          'error',
          'Une erreur est survenue lors de la récupération des détails de votre abonnement.',
        ),
      )
    }
  }

  handleRecyclageSubmit() {
    const { dispatch } = this.props

    fetch(`/api/user/${this.props.currentUser.id}/submit-recyclage`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        dispatch(setCurrentUser(res))
        dispatch(notify('success', 'Vous avez bien souscrit à l’option recyclage.'))
      })
      .catch(() => dispatch(notify('error', 'Une erreur est survenue.')))
  }

  getTTCCustomerBalance() {
    const { customer } = this.state
    if (!customer) return 0
    const { balance } = customer

    return Math.round(balance * 0.833333 * 100) / 100 // we receive it in TTC
  }

  renderSubscriptionInfo(contract) {
    const { subscription, prorationsLoading, changeOfferLoading, nextInvoice } = this.state
    const { currentUser } = this.props

    let nextPayment = ''
    if (subscription && nextInvoice) {
      const priceTotal = nextInvoice.total_excluding_tax
      nextPayment = `${formatPrice(priceTotal / 100)} HT le ${moment
        .unix(subscription.current_period_end)
        .format('DD/MM/YYYY')}`
    }

    return (
      <div className="customer-area-info-block">
        <CustomerAreaInfoLine
          label="Durée d'engagement"
          value={this.formatEngagementDuration(get(contract, 'engagement', ''))}
        />
        <CustomerAreaInfoLine
          label="Date d’inscription"
          value={this.formatDate(get(contract, 'created_at'))}
        />
        <CustomerAreaInfoLine label="Prochain paiement" value={nextPayment} />
        <CustomerAreaInfoLine
          label="Fréquence de facturation"
          value={
            <InjectedChangeIntervalForm
              onClickChangeInterval={stripe => this.getIntervalProrationsAndOpenModal(stripe)}
              subData={this.getSubData()}
              subscription={this.state.subscription}
              prorationsLoading={prorationsLoading}
              changeOfferLoading={changeOfferLoading}
              multipleYearsOffer={currentUser.contracts[0].offer_version === 'v2'}
            />
          }
        />
      </div>
    )
  }

  renderTooltip() {
    const tooltipText = (
      <div className="Tooltip">
        Vos courriers sont stockés 3 mois à partir de la date de numérisation, puis sont détruits et
        recyclés au lieu de vous être réexpédiés. En cas de courrier important, vous avez 3 mois
        pour nous demander la réexpédition gratuite à votre domicile.
      </div>
    )

    return (
      <Tooltip placement="top" trigger={['click']} overlay={tooltipText}>
        <div className="Tooltip-trigger">?</div>
      </Tooltip>
    )
  }

  renderOptions(contract) {
    const { prorationsLoading, changeOfferLoading, subscription } = this.state
    const { expedition_frequency, offer_version } = contract
    const options = this.formatOptions(expedition_frequency)
    
    let subscribeToScanButton = null
    let recyclageLine = null

    // for old offers with free expedition and non-free scan
    if (!offer_version) {
      if (['exp1', 'exp4'].includes(expedition_frequency)) {
        subscribeToScanButton = (
          <div className='mt-4'>
            <InjectedSubscribeScanForm
              onClickChangeScan={stripe => this.handleClickChangeScan(stripe)}
              subscription={subscription}
              prorationsLoading={prorationsLoading}
              changeOfferLoading={changeOfferLoading}
            />
          </div>
        )
      }

      if (expedition_frequency === 'scan') {
        recyclageLine = (
          <CustomerAreaInfoLine
            label={
              <>
                <div className="label-with-tooltip">Option recyclage (gratuite)</div>
                {this.renderTooltip()}
              </>
            }
            value={
              <Button
                label="Souscrire"
                onClick={this.handleRecyclageSubmit.bind(this)}
                secondaryClass="primary small-height"
              />
            }
            secondaryClass="primary"
          />
        )
      }
    }

    return (
      <div className="customer-area-info-block">
        <CustomerAreaInfoLine label="Réexpédition courrier" value={get(options, 'expedition')} />
        <CustomerAreaInfoLine label="Scan numérique quotidien" value={
          <>
            {get(options, 'scan')}
            {subscribeToScanButton}
          </>
        }/>
        {recyclageLine}
      </div>
    )
  }

  formatOptions(selectedOption) {
    switch (selectedOption) {
      case 'scan':
      default:
        return { expedition: '1 fois / mois', scan: 'souscrit' }
      case 'exp4':
        return { expedition: '4 fois / mois', scan: 'Recevez votre courrier directement par email pour 10€ HT/mois sans engagement' }
      case 'exp1':
        return { expedition: '1 fois / mois', scan: 'Recevez votre courrier directement par email pour 10€ HT/mois sans engagement' }
      case 'scan_recyclage':
        return { expedition: 'stockage 3 mois puis recyclage', scan: 'souscrit' }
    }
  }

  formatDate(date) {
    return moment(date).format('DD MMMM YYYY')
  }

  formatEngagementDuration(duration) {
    if (duration === 'sans') {
      return '3 mois'
    }

    return `${duration.substring(0, duration.length - 1)} mois`
  }

  renderUnsubscribe() {
    return (
      <div className="customer-area-unsubscribe">
        <div className="customer-area-subtext">
          • Le désabonnement est réalisé sous 24h, sans préavis. Merci de faire votre demande quand
          vous êtes certain de ne plus vouloir recevoir aucun courrier à notre adresse.
          <br />
          <br />• Après désabonnement, vous n'aurez plus accès à votre espace client, et donc à vos
          factures. Merci de les télécharger avant de faire votre demande.
        </div>
        <Button
          label="Formulaire de désabonnement"
          onClick={() => window.open(UNSUBSCRIBE_TYPEFORM_URL, '_blank')}
          secondaryClass="primary small-height"
        />
      </div>
    )
  }

  render() {
    const { currentUser } = this.props
    if (!currentUser.company) return null
    const stripeAccount = get(this.props, 'currentUser.company.idStripeAccount')
      ? get(this.props, 'currentUser.company.idStripeAccount')
      : undefined

    const contract = head(currentUser.contracts)

    return (
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY} stripeAccount={stripeAccount}>
        <Elements locale="fr">
          <CustomerNavigation currentPage="abonnement">
            <CustomerAreaTitle label="Mon abonnement" icon="ic-mon-abonnement" mobilePadding />
            {this.renderSubscriptionInfo(contract)}
            <CustomerAreaTitle label="Mes options" mobilePadding />
            {this.renderOptions(contract)}
            <CustomerAreaTitle label="Désabonnement" mobilePadding />
            {this.renderUnsubscribe()}
          </CustomerNavigation>
        </Elements>
      </StripeProvider>
    )
  }
}

class ChangeIntervalForm extends Component {
  render() {
    const { subscription, subData, onClickChangeInterval, prorationsLoading, changeOfferLoading, stripe, multipleYearsOffer } = this.props
    if (!subscription) return ''
    const { basePrice, interval, interval_count } = subData

    if (multipleYearsOffer) {
      let period = 'Mensuelle'
      if (interval === 'year') {
        period = `${interval_count} an${interval_count > 1 ? 's' : ''}`
      }

      return (
        <div className="interval-offers">
          <div className="interval-offers-line">
            <div className="col1">{period}</div>
            <div className="col2"></div>
            <div className="col3"></div>
          </div>
        </div>
      )
    }

    let monthlyButton = 'Souscrit'

    if (interval !== 'month') {
      if (moment.unix(subscription.current_period_end).diff(moment(), 'days', true) > 30) {
        monthlyButton = ''
      } else {
        monthlyButton = (
          <Button
            label={
              prorationsLoading || changeOfferLoading ? (
                <div className="flex justify-center align-center">
                  <img height="15px" src="/assets/images/spinner.gif" alt="Veuillez patienter" />
                </div>
              ) : (
                'Souscrire'
              )
            }
            onClick={() => onClickChangeInterval(stripe)}
            secondaryClass="primary small-height"
          />
        )
      }
    }

    return (
      <div className="interval-offers">
        <div className="interval-offers-line">
          <div className="col1">Mensuelle</div>
          <div className="col2">{formatPrice(basePrice / 100, true)} HT/mois</div>
          <div className="col3">{monthlyButton}</div>
        </div>
        <div className="interval-offers-year-block">
          <div className="interval-offers-year-header">Recommandé</div>
          <div className="interval-offers-line">
            <div className="col1">
              <div className="title">Annuelle</div>
              <div className="interval-offers-year-explanation">
                <div className="bold">1 mois offert</div>
                <div className="">1 seule facture à transmettre à votre comptable !</div>
              </div>
            </div>
            <div className="col2">
              <div className="">{formatPrice((basePrice / 100) * 11, true)} HT/an</div>
              <div className="price-small">
                (soit {formatPrice(((basePrice / 100) * 11) / 12, true)} HT/mois)
              </div>
            </div>
            <div className="col3">
              {interval === 'year' ? (
                'Souscrit'
              ) : prorationsLoading ? (
                <SpinnerButton />
              ) : (
                <Button
                  disabled={changeOfferLoading}
                  label={
                    changeOfferLoading ? (
                      <div className="flex justify-center align-center">
                        <img height="15px" src="/assets/images/spinner.gif" alt="Veuillez patienter" />
                      </div>
                    ) : (
                      'Souscrire'
                    )
                  }
                  onClick={() => onClickChangeInterval(stripe)}
                  secondaryClass="primary small-height"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
class SubscribeScanForm extends Component {
  render() {
    const { subscription, onClickChangeScan, prorationsLoading, stripe, changeOfferLoading } = this.props
    if (!subscription) return ''
    const isLoading = prorationsLoading || changeOfferLoading

    return (
      <Button
        disabled={isLoading}
        label={
          isLoading ? (
            <div className="flex justify-center align-center">
              <img height="15px" src="/assets/images/spinner.gif" alt="Veuillez patienter" />
            </div>
          ) : (
            'Souscrire'
          )
        }
        onClick={() => onClickChangeScan(stripe)}
        secondaryClass="primary small-height"
      />
    )
  }
}

class SpinnerButton extends Component {
  render() {
    return (
      <label className={`primary small-height flex justify-center align-center`}>
        <img src="/assets/images/spinner.gif" alt="Veuillez patienter" />
      </label>
    )
  }
}

const InjectedChangeIntervalForm = connect()(injectStripe(ChangeIntervalForm))
const InjectedSubscribeScanForm = connect()(injectStripe(SubscribeScanForm))

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(SubscriptionPage)
