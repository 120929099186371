import StripePaymentElement, { StripePaymentElementProps } from "@components/StripePaymentElementWithoutIntent"

const SubscriptionPaymentModal = (props: StripePaymentElementProps & { isSubmitting: boolean }) => (
  <div className="p-8 gap-4 md:w-[512px]">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <StripePaymentElement {...props} />
  </div>
)

export default SubscriptionPaymentModal
