import React, { Component } from 'react'
import { connect } from 'react-redux'

import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import { notify } from '../../../actions/notifications'
import Dropzone from 'Ui/Dropzone'
import { getUser } from '../../../api/user'
import { setCurrentUser } from 'Actions/user'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AdressPage/editAddressModal.sass'


const initialState = {
  isKbisSubmitting: false,
  kbis: {
    file: null,
    error: null,
  },
}

class KbisModal extends Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  handleSubmitKbis() {
    const { close, currentUser, dispatch } = this.props
    const { kbis } = this.state
    if (!kbis.file) return

    const formData = new FormData()
    formData.append('kbis', kbis.file)
    this.setState({ isKbisSubmitting: true })

    fetch(`/api/user/${currentUser.id}/kbis`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then(res => {
        console.log('res --------------------------------->')
        console.log(res)
        
        if (res.status !== 200) throw new Error(res.error)
        return res
      })
      .then(res => {
        this.setState({ isKbisSubmitting: false })
        close()
        dispatch(notify('success', 'Kbis enregistré avec succès.'))
        // refresh user data
        getUser(res => {
          dispatch(setCurrentUser(res))
        })
      })
      .catch((e) => {
        console.log(e)

        this.setState(initialState)

        dispatch(notify('error', 'Le document est impossible à analyser, merci de l’adresser par email à contact@ma-domiciliation.fr', 10000))
      })
  }

  renderKbisDropzone() {
    const file = this.state.kbis.file

    if (file)
      return (
        <div className="selected-file">
          <img
            width="20"
            height="20"
            className="checkmarck-icon"
            src="/assets/icons/ic-checkmark-green.svg"
            alt="Validé"
          />
          <span>Fichier sélectionné : {file.name}</span>
        </div>
      )

    const onDrop = files => {
      if (files.length > 1) {
        this.setState({
          kbis: { error: 'Veuillez sélectionner un seul fichier' },
        })

        return
      }

      if (files[0].size >= 20971520) {
        this.setState({
          kbis: { error: 'Ce fichier est trop volumineux (20Mo maximum).' },
        })

        return
      }

      this.setState({
        kbis: { file: files[0] },
      })
    }

    return <Dropzone onDrop={onDrop} />
  }

  renderKbisQuestion() {
    const { isKbisSubmitting, kbis } = this.state

    return (
      <div className="form-block">
        <div className="label-form">
          <span className="step-subtitle">Envoi de votre kbis</span>
        </div>
        <div className="ppe-text mb-5">
          Merci de nous faire parvenir votre nouveau Kbis, avec notre adresse de domiciliation en tant que siège social. Vous pouvez obtenir un Kbis gratuitement en vous créant un compte sur le service public <a href="https://monidenum.fr/" className="blue" target="_blank" rel="noopener noreferrer">https://monidenum.fr</a>. Si vous êtes auto-entrepreneur sans Kbis, merci de télécharger votre extrait SIRENE de l’INSEE.
        </div>
        <div className="mb-5">
          {this.renderKbisDropzone()}
        </div>
        {kbis.error && (
          <div className="error">
            <p>{kbis.error}</p>
          </div>
        )}
        <div className="right-container mb-8">
          <SubmitSpinnerButton isSubmitting={isKbisSubmitting}>
            <button className="btn valid small-height" onClick={() => this.handleSubmitKbis()}>
              Valider
            </button>
          </SubmitSpinnerButton>
        </div>
      </div>
    )
  }

  render() {
    const { unclosable } = this.props

    return (
      <div className="AmlModal">
        {this.renderKbisQuestion()}
        {!unclosable && (
          <div className="mt-8 center-container">
            <button className="btn valid small-height close-btn" onClick={() => this.props.close()}>
              Plus tard
            </button>
          </div>
        )}
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(KbisModal)
