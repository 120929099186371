import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'
import moment from 'moment'

import Button from '../../ui/Button'
import { openModal } from 'Actions/ui'
import { notify } from 'Actions/notifications'
import calculReexpeditionDate, {
  calculUrgentReexpeditionDate,
} from 'Helpers/calcul-reexpedition-date'
import fetchWrapper from 'Helpers/fetch-wrapper'
import { setCurrentUser } from '../../../actions/user'

const isProcuration = xelians_id => xelians_id && xelians_id.includes('MAD.60')

class LetterBoxDocument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
      isEditingName: false,
      customNameInput: '',
      renvoiLoading: false,
    }
  }

  handleNameSubmit(e) {
    e.preventDefault()
    const { id, dispatch, refetchDocs } = this.props
    const { customNameInput } = this.state

    fetchWrapper(`/api/user/documents/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        custom_name: customNameInput,
      }),
    })
      .then(() => {
        this.toggleEditCustomName(false)
        refetchDocs()
      })
      .catch(() => {
        this.toggleEditCustomName(false)
        dispatch(notify('error', 'Une erreur est survenue, impossible de modifier le nom.'))
      })
  }

  getPaymentMeanForSourceAccount() {
    const { dispatch } = this.props
    dispatch(openModal('getCbForMainAccountModal', {
      onValidate: paymentMethodId => this.sendRenvoiUrgentRequest(paymentMethodId),
      closeAfterValidate: true,
    }))
  }

  onRenvoiConfirm() {
    const { currentUser } = this.props
    this.setState({ renvoiLoading: true })

    if (currentUser.company.idStripeAccount && !currentUser.source_customer_id) {
      // need to get CB and create source customer account
      this.getPaymentMeanForSourceAccount()
      return
    } else {
      this.sendRenvoiUrgentRequest()
    }
  }

  sendRenvoiUrgentRequest(paymentMethodId = null) {
    const { id, stripe, refetchDocs, dispatch } = this.props

    return fetchWrapper(`/api/user/documents/renvoi-urgent`, {
      method: 'POST',
      body: JSON.stringify({ documentId: id, paymentMethodId }),
    })
      .then(res => {
        const { validated, errorCode, client_secret, payIntId, invoiceId, user } = res
        this.setState({ renvoiLoading: false })
        
        // if we created a Source Stripe Account, need to update user in state to prevent creating a second one
        if (user) {
          dispatch(setCurrentUser(user))
        }

        if (errorCode === 'ALREADY_SENT') {
          dispatch(notify('info', `Après vérification, ce courrier a déjà été réexpédié le ${res.reexpDate}`, 8000))
          refetchDocs()
          return
        }

        if (validated) {
          dispatch(notify('success', 'Demande de renvoi validée.'))
          refetchDocs()
        } else {
          if (errorCode === 'card_declined') {
            dispatch(notify('error', 'Le paiement a échoué, la demande va être annulée.'))

            return
          }

          return stripe.handleCardPayment(client_secret, {}).then(res => {
            console.log('res handleCardPayment ------------------------------------------>')
            console.log(res)

            return fetchWrapper(`/api/user/documents/renvoi-urgent/payment-confirmed`, {
              method: 'POST',
              body: JSON.stringify({ documentId: id, payIntId, invoiceId }),
            }).then(res => {
              this.setState({ renvoiLoading: false })

              if (res.validated) {
                dispatch(notify('success', 'Demande de renvoi validée.'))
                refetchDocs()
              } else {
                dispatch(notify('error', 'Le paiement a échoué, la demande va être annulée.'))
              }
            })
          })
        }
      })
      .catch(e => {
        console.log('e ------------------------------------------>')
        console.log(e)
        this.setState({ renvoiLoading: false })
        this.props.dispatch(
          notify('error', 'Une erreur est survenue lors de la demande de renvoi urgent.'),
        )
      })
  }

  onRenvoiClick() {
    this.props.dispatch(
      openModal('notification', {
        message: (
          <span>
            Réexpédition urgente de votre courrier.
            <br />
            <br />
            Si vous avez besoin rapidement d’un courrier en original, vous pouvez demander la réexpédition en urgence de votre courrier.
            Le courrier sera renvoyé le lendemain de votre demande (hors WE et jours fériés). Le coût de cet envoi est de 3,5€HT
            par courrier, correspondant principalement aux frais d’affranchissement de la poste.
            <br />
          </span>
        ),
        onButtonClick: () => this.onRenvoiConfirm(),
        buttonText: 'Valider la demande de renvoi urgent (3,5€ HT)',
      }),
    )
  }

  toggleEditCustomName(value) {
    this.setState({
      isEditingName: value,
    })
  }

  renderCustomName() {
    const { customNameInput, isEditingName } = this.state
    const { customName } = this.props

    if (customName && !isEditingName) {
      return (
        <div className="letter-name-container">
          <span className="letter-name">{customName}</span>
          <span className="letter-name-edit-button">
            <img src="/assets/icons/icon-edit.svg" />
          </span>
        </div>
      )
    }

    return (
      <form onSubmit={this.handleNameSubmit.bind(this)}>
        <input
          placeholder="Donnez un nom à ce courrier"
          className="custom-name-input"
          maxLength="30"
          type="text"
          value={customNameInput}
          onChange={e => this.setState({ customNameInput: e.target.value })}
        />
        <Button
          secondaryClass="valid small inline"
          label="Ok"
          type="button"
          onClick={this.handleNameSubmit.bind(this)}
        />
      </form>
    )
  }

  render() {
    const { name, isMobile, createdAt, renvoiRequestDate, expFrequency, xelians_id } = this.props
    const { isOpened } = this.state
    const dateFormat = isMobile ? 'DD/MM' : 'DD/MM/YYYY'

    const scanDate = moment(createdAt).format(dateFormat)
    let expeditionDate = ''
    if (renvoiRequestDate) {
      expeditionDate = calculUrgentReexpeditionDate(renvoiRequestDate).format(dateFormat)
    } else {
      if (isProcuration(xelians_id) || !xelians_id) {
        expeditionDate = ''
      } else {
        if (expFrequency === 'scan') expeditionDate = calculReexpeditionDate(createdAt).format(dateFormat)
        else if (expFrequency === 'scan_recyclage') expeditionDate = isMobile ? 'recycl.' : 'recyclage'
      }
    }

    return (
      <div
        className={`letter-box-document-container ${
          isOpened ? 'document-container-highlight' : ''
        }`}
      >
        <div className={`letter-box-document-main ${isOpened ? 'document-main-highlight' : ''}`}>
          <div
            className="letter-box-document-block"
            onClick={() => this.toggleEditCustomName(true)}
          >
            <div className="letter-name-block">
              {this.renderCustomName()}
              <div className="letter-name small">
                {isMobile ? 'Num' : 'Numéro'}: {name}
              </div>
            </div>
          </div>
          <div className="letter-box-date">{scanDate}</div>
          <div className="letter-box-date">{expeditionDate}</div>
          <div className="letter-box-buttons">{this.renderButtons()}</div>
        </div>
        <div className={`letter-box-buttons-mobile ${isOpened ? '' : 'letter-box-closed'}`}>
          {this.renderButtons()}
        </div>
        <div
          className="letter-box-document-opener"
          onClick={() => this.setState({ isOpened: !isOpened })}
        >
          <img
            src={`/assets/icons/ic-${isOpened ? 'hide' : 'dropdown'}.svg`}
            alt={isOpened ? 'Fermer' : 'Ouvrir'}
          />
        </div>
      </div>
    )
  }

  renderButtons() {
    const { renvoiLoading } = this.state
    const { displayUrl, renvoiRequestDate, createdAt, expFrequency, xelians_id } = this.props
    let renvoiButton = (
      <Button label="Renvoi urgent" secondaryClass="primary" onClick={() => this.onRenvoiClick()} />
    )
    if ((calculReexpeditionDate(createdAt).isBefore(moment().add(1, 'day'))
        && !(expFrequency === 'scan_recyclage' && moment(createdAt).isAfter(moment().add(-3, 'month'))))
      || isProcuration(xelians_id) || !xelians_id) {
      renvoiButton = ''
    }
    if (renvoiRequestDate) renvoiButton = 'Renvoi demandé'
    if (renvoiLoading)
      renvoiButton = <img height="15px" src="/assets/images/spinner.gif" alt="Veuillez patienter" />

    return (
      <div className="letter-box-buttons-line">
        <a href={displayUrl} target="_blank" rel="noopener noreferrer" className="letter-box-btn">
          <Button label="Voir" secondaryClass="primary" />
        </a>
        <div className="letter-box-btn">{renvoiButton}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(injectStripe(LetterBoxDocument))
