import React, { Component } from 'react'
import { connect } from 'react-redux'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements'
import moment from 'moment'
import { get } from 'lodash'

import Button from 'Ui/Button'
import { notify } from 'Actions/notifications'
import { formatPrice } from 'Helpers/format-helper'
import { GTM_ENV } from 'Config/constants'

import { STRIPE_PUBLIC_KEY } from 'Config/constants'

import './annual-offer-modal.sass'

class AnnualOfferModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      remainingTime: this.getRemainingTime(),
      subscription: null,
      nextInvoice: null,
      changeIntervalLoading: false,
    }
  }

  componentDidMount() {
    this.fetchSubscriptions()

    this.interval = setInterval(() => {
      this.setState({
        remainingTime: this.getRemainingTime()
      })
    }, 60000)
  }

  getEndDate() {
    const startDate = moment('31/10/2024', 'DD/MM/YYYY').endOf('day')
    while (moment().isAfter(startDate)) {
      startDate.add(2, 'day')
    }
    return startDate
  }

  getRemainingTime() {
    const endDate = this.getEndDate()
    return moment.duration(endDate.diff(moment()))
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  fetchSubscriptions() {
    fetch(`/api/user/subscription-details`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        this.setState({ subscription: res.subscription, nextInvoice: res.nextInvoice })
      })
      .catch(() =>
        this.props.dispatch(
          notify(
            'error',
            'Une erreur est survenue lors de la récupération des détails de votre abonnement.',
          ),
        ),
      )
  }

  reloadPage() {
    setTimeout(() => {
      // don't need this if no needSignContract
      // we reload page, the yousign signature is handled on other modal depending on user data
      window.location.reload()
    })
  }

  handleChangeInterval(stripe) {
    const { dispatch, close, currentUser } = this.props
    this.setState({ changeIntervalLoading: true })
    // only for coworkings users
    // const needSignContract = currentUser.company.id !== (GTM_ENV === 'staging' ? 5 : 1)
    const needSignContract = false

    fetch(`/api/user/change-payment-interval`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ interval: 'year', offer4month: true, needSignContract }),
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        console.log('res change interval ------------------------------------------>')
        console.log(res)
        const { validated, client_secret } = res

        if (validated) {
          this.setState({ changeIntervalLoading: false })
          dispatch(notify('success', 'Changement validé.'))
          close()
        } else {
          return stripe.handleCardPayment(client_secret, {}).then(res => {
            console.log('res handleCardPayment ------------------------------------------>')
            console.log(res)
            this.setState({ changeIntervalLoading: false })

            if (res.error) {
              dispatch(
                notify(
                  'error',
                  'Changement d’offre validé, mais le paiement a été refusé, veuillez vérifier l’approvisionnement de votre carte.',
                ),
              )

              return
            }

            dispatch(notify('success', 'Changement validé.'))
            this.reloadPage()
          })
        }
        this.reloadPage()
      })
      .catch(e => {
        console.log('e ------------------------------------------>')
        console.log(e)
        this.setState({ changeIntervalLoading: false })
        this.props.dispatch(
          notify('error', 'Une erreur est survenue lors de la modification de votre abonnement.'),
        )
      })
  }

  render() {
    const { remainingTime, subscription, changeIntervalLoading } = this.state
    const { currentUser, close } = this.props

    const endDate = this.getEndDate().format('DD/MM')

    if (!currentUser.company || !subscription) return null
    
    const stripeAccount = get(this.props, 'currentUser.company.idStripeAccount')
      ? get(this.props, 'currentUser.company.idStripeAccount')
      : undefined

    const monthlyPrice = subscription.items.data.reduce((acc, item) => acc + item.plan.amount, 0)

    const priceWithoutDiscount = monthlyPrice * 12
    const priceWithDiscount = monthlyPrice * 8
    const priceWithDiscountTTC = priceWithDiscount * 1.2

    return (
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY} stripeAccount={stripeAccount}>
        <Elements locale="fr">
          <div className="AnnualOfferModal">
            <img onClick={() => close()} src="/assets/icons/icon-cross.svg" className="notif-modal-close" alt="fermer" />
            <div className="images">
              <img src="/assets/images/annual-offer-modal/euro-1.png" className="euro-1" alt="icon euro" />
              <img src="/assets/images/annual-offer-modal/euro-2.png" className="euro-2" alt="icon euro" />
              <img src="/assets/images/annual-offer-modal/euro-3.png" className="euro-3" alt="icon euro" />
              <img src="/assets/images/annual-offer-modal/calendar-1.png" className="calendar-1" alt="icon calendar" />
              <img src="/assets/images/annual-offer-modal/calendar-2.png" className="calendar-2" alt="icon calendar" />
              <img src="/assets/images/annual-offer-modal/calendar-3.png" className="calendar-3" alt="icon calendar" />
            </div>

            <h3>Black friday</h3>
            <div className="subtitle">
              <span className="bold">4 mois offerts </span>
              pour 1 an d’abonnement
            </div>

            <div className="separator-line"/>
            <div className="price-without">{formatPrice(priceWithoutDiscount / 100, true)}</div>
            <div className="price-with">
              {formatPrice(priceWithDiscount / 100, true)}
              <span className="indix">HT/an</span>
            </div>
            <div className="price-difference">soit {formatPrice((priceWithoutDiscount - priceWithDiscount) / 100, true)} HT d'économies</div>

            <div className="separator-line mb-8"/>
            
            <div className="mb-3">
              <InjectedChangeIntervalButton
                changeIntervalLoading={changeIntervalLoading}
                onClick={stripe => this.handleChangeInterval(stripe)}
              />
            </div>

            <div className="small-text mb-3">Offre valable jusqu'au {endDate}</div>

            <div className="countdown-container">
              <div className="countdown-item">
                <div className="countdown-number">{remainingTime.days()}</div>
                <div className="small-text">Jours</div>
              </div>
              <div className="countdown-item">
                <div className="countdown-number">{remainingTime.hours()}</div>
                <div className="small-text">Heures</div>
              </div>
              <div className="countdown-item">
                <div className="countdown-number">{remainingTime.minutes()}</div>
                <div className="small-text">Minutes</div>
              </div>
            </div>

            <div className="small-text">Montant incluant la TVA : {formatPrice(priceWithDiscountTTC / 100)} TTC. Le montant de votre abonnement en cours sera déduit.</div>
          </div>
        </Elements>
      </StripeProvider>
    )
  }
}

class ChangeIntervalButton extends Component {

  render() {
    const { stripe, onClick, changeIntervalLoading } = this.props

    return (
      <Button
        label={
          changeIntervalLoading ? (
            <img
              height="15px"
              src="/assets/images/spinner.gif"
              alt="Veuillez patienter"
              style={{margin: '0 auto'}}
            />
          ) : (
            <>
              J’EN PROFITE<br/>
              ET JE PAIE 1 AN D’ABONNEMENT
            </>
          )
        }
        onClick={() => onClick(stripe)}
        secondaryClass="primary small-height bold"
      />
    )
  }
}

const InjectedChangeIntervalButton = connect()(injectStripe(ChangeIntervalButton))

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const MapDispatchToProps = dispatch => ({
  dispatch,
})

export default connect(MapStateToProps, MapDispatchToProps)(AnnualOfferModal)
