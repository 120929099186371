import { useCallback, useMemo, useState } from 'react'
import { STRIPE_PUBLIC_KEY } from 'Config/constants'
import {
  useStripe, Elements, useElements, PaymentElement,
} from '@stripe/react-stripe-js'
import { Stripe, StripeElements, StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import Button from '@components/Button'


export type StripePaymentElementProps = {
  options: StripeElementsOptions
  stripeAccount: string | null
  returnUrl: string
  onPaymentSuccess: (param: { paymentIntentId: string }) => void
  getClientSecret: () => Promise<{ clientSecret: string, customerId: string, subscriptionId: string, idStripeAccount: string, error: any }>
  close?: () => void
  onError: (param?: { message?: string, customerId?: string, idStripeAccount?: string, subscriptionId?: string }) => void
  hiddenButton?: boolean
}


const StripePaymentElement = ({
  options, stripeAccount, onPaymentSuccess, returnUrl, onError, getClientSecret, hiddenButton, close
}: StripePaymentElementProps) => {
  const stripePromise = useMemo(() => loadStripe(
    STRIPE_PUBLIC_KEY,
    {
      locale: 'fr-FR',
      ...(stripeAccount ? { stripeAccount } : {}),
    },
  ), [stripeAccount])

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentElementForm
        onPaymentSuccess={onPaymentSuccess}
        getClientSecret={getClientSecret}
        onError={onError}
        returnUrl={returnUrl}
        hiddenButton={hiddenButton}
        close={close}
      />
    </Elements>
  )
}

const StripePaymentElementForm = ({
  onPaymentSuccess, returnUrl, onError, getClientSecret, hiddenButton, close
}: Pick<StripePaymentElementProps, 'onPaymentSuccess' | 'returnUrl' | 'onError' | 'getClientSecret' | 'hiddenButton' | 'close'>) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)
    try {
      if (!stripe || !elements || !onPaymentSuccess) return

      const { clientSecret, customerId, subscriptionId, idStripeAccount, error: clientSecretError } = await getClientSecret()
      const { error: submitError } = await elements.submit()
      console.log('clientSecretError --------------------------------->')
      console.log(clientSecretError)
      console.log('submitError --------------------------------->')
      console.log(submitError)
      
      if (clientSecretError) return onError({ message: 'Une erreur est survenue.'})
      if (submitError) return onError({ message: submitError.message || 'Une erreur est survenue.' })

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
      })

      console.log('paymentIntent --------------------------------->')
      console.log(paymentIntent)
      console.log('error --------------------------------->')
      console.log(error)

      if (error) {
        setErrorMessage(error.message)
        onError({ message: error.message, customerId, subscriptionId, idStripeAccount })
        setSubmitting(false)
      } else {
        onPaymentSuccess({ paymentIntentId: paymentIntent.id })
        close && close()
      }
      setSubmitting(false)
    } catch (e) {
      console.log('handleSubmit error --------------------------------->')
      console.log(e)
      setSubmitting(false)
      setErrorMessage('Une erreur est survenue lors du paiement, veuillez réessayer.')
    }
  }, [elements, setErrorMessage, stripe])

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {hiddenButton ? (
        <input type="submit" id="submit-cb-form" style={{ display: 'none' }} />
      ) : (
        <Button
          isDisabled={!stripe}
          type="submit"
          isLoading={isSubmitting}
          className="mt-4"
        >
          <label
            htmlFor="submit-cb-form"
            tabIndex={0}
          >
            PAIEMENT
          </label>
        </Button>
      )}
      {errorMessage && <div className="mt-4 text-pastel-red">{errorMessage}</div>}
    </form>
  )
}

export default StripePaymentElement